<template>
  <ejs-grid
    ref="grid"
    :dataSource="data"
    :editSettings="editSettings"
    :toolbar="toolbar"
    :toolbarClick="clickHandler"
    :allowPaging="true"
    :allowSorting="true"
    :allowFiltering="true"
    :filterSettings="filterOptions"
    :allowReordering="true"
    :allowResizing="true"
    :showColumnChooser="true"
    :allowGrouping="false"
    :groupSettings="groupOptions"
    :showColumnMenu="true"
    :searchSettings="searchOptions"
    :childGrid="childGrid"
    :actionFailure="actionFailure"
    :actionBegin="actionBegin"
    :actionComplete="actionComplete"
    :dataBound="onDataBound"
  >
    <!-- :toolbarClick='clickHandler' -->
    <slot></slot>
  </ejs-grid>
</template>
<script>
import {
  Toolbar,
  Edit,
  Page,
  Sort,
  Filter,
  Search,
  Group,
  Reorder,
  Resize,
  ColumnChooser,
  ColumnMenu,
  ForeignKey,
  DetailRow,
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "TB",
  props: ["tb_config"],
  data() {
    // configurazione ricevuta
    // console.log(this.tb_config);
    // configurazione di default
    let b_config = {
      // tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
      childGrid: null,
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Dialog",
      },
      pageSettings: {
        pageSize: 5,
      },
      filterOptions: {
        type: "Menu",
      },
      filter: {
        type: "CheckBox",
      },
      groupOptions: { showGroupedColumn: false },
      // toolbar: [],
      toolbar: [
        {
          text: "Refresh",
          tooltipText: "Aggiorna dati",
          // prefixIcon: "e-add",
          id: "Refresh",
          align: "Right",
        },
        "Search",
        "ColumnChooser",
      ],
      searchOptions: {},
      // toolbarOptions: [
      //   // {
      //   //   text: "Refresh",
      //   //   tooltipText: "Refresh data",
      //   //   prefixIcon: "e-expand",
      //   //   id: "refresh",
      //   // },
      //   // "Add",
      //   // "Edit",
      //   // "Delete",
      //   // "Update",
      //   // "Cancel",
      //   // { text: 'Collapse All', tooltipText: 'collection All', prefixIcon: 'e-collapse', id: 'collapseall' , align:'Right'},
      //   {
      //     text: "Refresh",
      //     tooltipText: "Refresh data",
      //     prefixIcon: "e-add",
      //     id: "refresh",
      //     align: "Right",
      //   },
      //   {
      //     text: "Expand All",
      //     tooltipText: "Expand All",
      //     prefixIcon: "e-expand",
      //     id: "expandall",
      //   },
      //   {
      //     text: "Collapse All",
      //     tooltipText: "collection All",
      //     prefixIcon: "e-collapse",
      //     id: "collapseall",
      //     align: "Right",
      //   },
      //   "Search",
      //   "ColumnChooser",
      // ],
    };
    // merge configurazione
    var _ = require("lodash");
    // let config = Object.assign({}, b_config, this.tb_config);
    let config = _.mergeWith(
      b_config,
      this.tb_config,
      function (objValue, srcValue) {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      }
    );
    console.log(config);
    return config;
  },
  methods: {
    // dataSourceChanged: function(args){
    //   console.log(args)
    //   if(args.action === "edit"){
    //     args.endEdit(); // once data updated successfully then call endEdit function
    //   }

    // },ImageCropper
    onDataBound: function () {
      //this.$refs.grid.autoFitColumns(); // initially 2nd chid Grid will expand.
    },

    clickHandler: function (args) {
      if (args.item.id === "Refresh") {
        this.$refs.grid.refresh();
      }
    },

    actionFailure: function (args) {
      let errors = JSON.parse(args.error[0].error.response);
      // console.log(errors)
      let self = this;
      Object.keys(errors).forEach(function (key) {
        // console.log(key)
        // console.log(self.$refs.grid.ej2Instances.editModule.formObj)
        self.$refs.grid.ej2Instances.editModule.formObj.addRules(key, {
          required: [false, errors[key][0]],
        });
      });
      this.$refs.grid.ej2Instances.editModule.formObj.validate();
      // console.log(args)
      // console.log(args.requestType)
      // if (args.requestType == "save") {
      //   console.log("Save request")
      // }
    },
    actionComplete: function (args) {
      // console.log(args)
      // console.log(args.requestType === "save")
      if (args.requestType === "save") {
        if (this.tb_config.reload_on_save) {
          // console.log("refresh")
          this.$refs.grid.refresh();
        }
      }
    },
    actionBegin: function () {
      //       if (args.requestType === "save") {
      //         console.log("actionBegin", args.requestType, args)
      //         console.log(args.form)
      //         console.log(this.$refs.grid.ej2Instances.editModule.formObj)
      //         // this.$refs.grid.ej2Instances.editModule.formObj.addRules("OrderID",{ required: true, number: true });
      //         // this.$refs.grid.ej2Instances.editModule.formObj.addRules("Freight",{ number: true,required: true });
      // //         this.$refs.grid.ej2Instances.editModule.formObj.addRules("CustomerID",{ required: true });
      // // // here you will get true or false based on the validationRules
      // //         if (!this.$refs.grid.ej2Instances.editModule.formObj.validate()) {
      // // //cancel the save action, if the validation rules are not satisfied
      // //           args.cancel = true;
      // //         }
      //       }
    },
    // clickHandler: function (args) {
    //   console.log("click");
    // //   console.log(args);
    // //   console.log(this.$grid)
    // //   if (args.item.id === "refresh") {
    // //     console.log(this.data)
    // //     this.data = []
    // //   }
    // },
  },
  provide: {
    grid: [
      Edit,
      Toolbar,
      Page,
      Sort,
      Filter,
      Search,
      Reorder,
      Resize,
      ColumnChooser,
      ColumnMenu,
      Group,
      ForeignKey,
      DetailRow,
    ],
  },
};
// @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/multi-select/bootstrap4.css";
</script>
